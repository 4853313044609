import React, { useState } from "react";
import Icons from "../../miscellaneous/icons/Icons";
import alcanzalogo from "../../photos&logos/letterallwhitebig.webp";
import Login from "../login/login/Login";
import SignUp from "../login/signup/SignUp";
import rm from "./Menu.module.scss";
/********************************************
 * NavigationMenu Mobile
 ********************************************/
export default function NavigationMenuMobile() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={rm.login_mobile}>
      <div className={rm.mobile_wrapper00}>
        <div className={rm.mobileimg_wrapper}>
          <SignUp />
        </div>
      </div>
      <div className={rm.mobile_wrapper01}>
        <div className={rm.mobile_login_path}>
          <img src={alcanzalogo} alt="logo" height={50} width={50} />
        </div>
      </div>
      <div className={rm.mobile_box00}>
        <div className={rm.mobile_box001}>
          {open ? (
            <Icons
              iconName="arrowupIcon"
              clicked={() => setOpen((prev) => !prev)}
            />
          ) : (
            <div className={rm.mobile_box001_vermas} onClick={() => setOpen((prev) => !prev)}>
              <h5>ver mas</h5>
              <Icons
                iconName="arrowdownIcon"
              />
            </div>
          )}
          {open ? (
            <h4>
              Accede o regístrate rápidamente en la plataforma con tu cuenta de
              Google
            </h4>
          ) : null}
        </div>

        <div className={rm.mobile_button}>
          <Login />
        </div>

        <div className={rm.box01}></div>
      </div>
      <div className={rm.mobile_wrapper02}></div>
    </div>
  );
}
