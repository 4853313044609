import { FaLongArrowAltRight, FaChalkboardTeacher } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import {
  RiMiniProgramLine,
  RiWhatsappFill,
  RiMenuFold2Line,
  RiMenuFoldLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
} from "react-icons/ri";
import { FcPrint } from "react-icons/fc";
import { IoReturnUpBack, IoReturnDownForwardOutline } from "react-icons/io5";
import { FaPlay, FaPause, FaGoogle, FaPlus } from "react-icons/fa";
import { GiFlagObjective } from "react-icons/gi";
import { CiPhone } from "react-icons/ci";
import { SiInfoq, SiMinutemailer, SiDassaultsystemes } from "react-icons/si";
import { MdReviews } from "react-icons/md";
import { FaChartLine, FaPeoplePulling } from "react-icons/fa6";
import { TfiCheckBox } from "react-icons/tfi";

import ic from "./Icons.module.scss";

/*************************
 * functions getIcons()
 ************************/
export function getIcons(iconName: string) {
  switch (iconName) {
    case "rightarrowIcon":
      return <FaLongArrowAltRight className={ic.rightarrow_icon} />;
    case "professorIcon":
      return <PiStudentFill className={ic.professor_teaching_icon} />;
    case "teachingIcon":
      return <FaChalkboardTeacher className={ic.professor_teaching_icon} />;
    case "dollarIcon":
      return <LiaFileInvoiceDollarSolid className={ic.dollar_program_icon} />;
    case "programIcon":
      return <RiMiniProgramLine className={ic.dollar_program_icon} />;
    case "systemIcon":
      return <SiDassaultsystemes className={ic.methodology_icon} />;
    case "infoIcon":
      return <SiInfoq className={ic.methodology_icon} />;
    case "objectiveIcon":
      return <GiFlagObjective className={ic.methodology_icon} />;
    case "reviewsIcon":
      return <MdReviews className={ic.methodology_icon} />;
    case "playIcon":
      return <FaPlay className={ic.play_icon} />;
    case "pauseIcon":
      return <FaPause className={ic.play_icon} />;
    case "whatsappIcon":
      return <RiWhatsappFill className={ic.whatsapp_icon} />;
    case "emailIcon":
      return <SiMinutemailer className={ic.email_icon} />;
    case "phoneIcon":
      return <CiPhone className={ic.whatsapp_icon} />;
    case "backIcon":
      return <IoReturnUpBack className={ic.back_icon} />;
    case "nextIcon":
      return <IoReturnDownForwardOutline className={ic.next_icon} />;
    case "chartIcon":
      return <FaChartLine className={ic.professor_teaching_icon} />;
    case "peopleIcon":
      return <FaPeoplePulling className={ic.dollar_program_icon} />;
    case "checkIcon":
      return <TfiCheckBox className={ic.check_icon} />;
    case "menuOpenIcon":
      return <RiMenuFold2Line className={ic.menu_icon} />;
    case "menuCloseIcon":
      return <RiMenuFoldLine className={ic.menu_icon} />;
    case "googleIcon":
      return <FaGoogle className={ic.google_icon} />;
    case "printIcon":
      return <FcPrint className={ic.print_icon} />;
    case "plusIcon":
      return <FaPlus className={ic.plus_icon} />;
    case "arrowdownIcon":
      return <RiArrowDownSLine className={ic.arrow_down_icon} />;
    case "arrowupIcon":
      return <RiArrowUpSLine className={ic.arrow_down_icon} />;
    default:
      break;
  }
}
